import { trim, mapValues } from 'lodash'
import kennitala from 'kennitala'

const cleanNumberString = (s) => trim(s).replace(/(\s|-)/g, '')

const ssn = {
  clean: cleanNumberString,
  validate: (s) => !kennitala.isValid(s) && 'Sláðu inn gilda kennitölu',
}

const name = {
  clean: trim,
}

const phone = {
  clean: cleanNumberString,
  validate: (s) => !/^(\+?\d+)$/.test(s) && 'Sláðu inn gilt símanúmer',
}

const email = {
  clean: trim,
  validate: (s) => !/^.+@.+\..+$/.test(s) && 'Þetta er ekki gilt netfang',
}

const terms = {
  initialValue: false,
  validate: (checked) => !checked && 'Þú átt eftir að samþykkja skilmála',
}

const bankAccGroup = {
  clean: cleanNumberString,
  validate: (s) => !/^(\+?\d+)$/.test(s) && 'Þetta er ekki gild höfuðbók',
}

const bankAccount = {
  clean: cleanNumberString,
  validate: (s) => !/^(\+?\d+)$/.test(s) && 'Þetta er ekki gilt reikningsnúmer',
}

const bankCode = {
  clean: cleanNumberString,
  validate: (s) => !/^(\+?\d+)$/.test(s) && 'Þetta er ekki gilt bankanúmer',
}


const cardNumber = {
  clean: cleanNumberString,
  validate: (s) => !/^\d{16}$/.test(s) && 'Þetta er ekki gilt kortanúmer',
}

const cardCvc = {
  clean: cleanNumberString,
  validate: (s) => !/^\d{3}$/.test(s) && 'Þetta er ekki gilt CVC',
}

const cardExpiry = {
  clean: trim,
  validate: (s) => !/^(0[1-9]|1[0-2])\/\d{2}$/.test(s) && 'Þetta er ekki gildur gildistími',
}

export const BANK_PAYMENT = 'bankpayment'
export const KREDITCARD = 'kreditcard'

export const allFields = {
  ssn,
  name,
  phone,
  email,
  terms,
  bankAccGroup,
  bankAccount,
  bankCode,
  cardNumber,
  cardExpiry,
  cardCvc,
}

export const initialValues = () => {
  return mapValues(allFields, (f) =>
    f.initialValue == null ? '' : f.initialValue
  )
}

export const cleanField = (value, name) => {
  if (allFields[name].clean) {
    value = allFields[name].clean(value)
  }
  return value
}

export const validateAmount = (value) => {
  let error

  if (!value || value < 0) {
    error = 'Sláðu inn gilda upphæð'
  }

  return error;
}

const fieldsRequired = (visiblePayment, monthly) => {
  const extras = []
  if(visiblePayment === BANK_PAYMENT) {
    extras.push('bankCode', 'bankAccGroup', 'bankAccount')
  }
  if(visiblePayment === KREDITCARD && monthly) {
    extras.push('cardNumber', 'cardExpiry', 'cardCvc')
  }
  return [
    ...extras,
    'ssn',
    'name',
    'phone',
    'email',
    'terms',
  ]
}

export const validateAll = (values, visiblePayment, monthly) => {
  const requiredFields = fieldsRequired(visiblePayment, monthly)

  const errors = {}
  requiredFields.forEach((name) => {
    const value = cleanField(values[name], name)
    const validator = allFields[name].validate
    const error =
      value === ''
        ? 'Þessi reitur má ekki vera tómur'
        : validator && validator(value)

    if (error) {
      errors[name] = error
    }
  })

  return errors
}
