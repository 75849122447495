import React from 'react'
import { DonationWidget } from '@cmp/site'

export default ({ data }) => {
  const {
    amounts,
    frequency,
    title: { text: title }
  } = data.data

  const frequencyData = frequency.map(item => {
    const {
      button_text: { text: buttonText },
      unit: { text: unit },
      text: { html: text },
      link: { url: link }
    } = item

    return { buttonText, unit, text, link }
  })

  return (
    <DonationWidget
      amounts={amounts}
      frequency={frequencyData}
      title={title}
    />
  )
}
